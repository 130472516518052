<template>
  <div>
    <v-form :disabled="searching" ref="form" @submit.prevent="save()">
      <v-progress-linear color="purple" v-if="saving" indeterminate />
      <p class="sug-name" @click="form.name = name" v-if="name">
        Nome sugerido: {{ name }} - clique para aceitar
      </p>
      <p class="sug-name" @click="form.name = name" v-if="already">
        CLIENTE JÁ CADASTRADO!
      </p>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            label="CNPJ/CPF*"
            :rules="[
              ...requiredRules,
              v => v && v.length <= 14 || 'Máximo 14.',
              v => v && v.length >= 11 || 'Mínimo 11.',
            ]"
            v-model="form.cnpj"
            append-outer-icon="mdi-account-search"
            @click:append-outer="getCustomerSefaz()"
            counter="14"
            :loading="requesting"
            @focusout="getCnpj"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="Nome do cliente*"
            :rules="requiredRules"
            v-model="form.name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">
          <v-select
            :items="cities"
            item-value="id"
            item-text="name"
            label="Cidade*"
            :loading="loading"
            :rules="requiredRules"
            v-model="form.city_id"
          ></v-select>
        </v-col>
        <v-col class="mt-5" cols="12" md="1">
          <a title="Adicionar Cidade" href="/#/cidades" target="_blank">
            <v-icon> mdi-plus </v-icon>
          </a>
          <v-icon title="Atualizar" @click="getCities"> mdi-refresh </v-icon>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field label="CEP" v-model="form.postal_code"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field label="Número" v-model="form.number"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field label="Telefone" v-model="form.phone"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea label="Endereço" v-model="form.address"></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            color="secondary"
            :loading="loading"
            block
            type="submit"
            :disabled="already"
            >Salvar</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import http from "@/modules/http";
export default {
  props: {
    name: {
      type: String,
      default: null,
    },
    onCte: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    usedCnpj() {
      return this.$store.state.current_cnpj;
    },
  },
  watch: {
    usedCnpj() {
      this.form.cnpj = this.usedCnpj;
    },
  },
  mounted() {
    this.getCities();
    this.form.cnpj = this.$store.state.current_cnpj;
  },
  data() {
    return {
      already: false,
      loading: false,
      saving: false,
      requesting: false,
      cities: [],
      form: {
        name: "",
        cnpj: "",
        phone: "",
        address: "",
        postal_code: "",
        city_id: "",
        number: "",
      },
      requiredRules: [(v) => !!v || "Este campo é obrigatório"],
      searching: false,
    };
  },
  methods: {
    getCnpj() {
      if (this.form.cnpj?.length > 10) {
        this.searching = true;
        http
          .get("api/customers?cnpj=" + this.form.cnpj)
          .then((res) => {
            this.searching = false;
            if (res.data.data.length > 0) {
              this.already = true;
              this.form = res.data.data[0];
            } else {
              this.already = false;
              this.form.name = "";
              this.form.city_id = "";
              this.form.postal_code = "";
              this.form.number = "";
              this.form.phone = "";
              this.form.address = "";
            }
          })
          .catch((err) => {
            this.searching = false;
            console.log(err);
          });
      }
    },
    retira_acentos(str) {
      let com_acento =
        "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";

      let sem_acento =
        "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
      let novastr = "";
      for (let i = 0; i < str.length; i++) {
        let troca = false;
        for (let a = 0; a < com_acento.length; a++) {
          if (str.substr(i, 1) == com_acento.substr(a, 1)) {
            novastr += sem_acento.substr(a, 1);
            troca = true;
            break;
          }
        }
        if (troca == false) {
          novastr += str.substr(i, 1);
        }
      }
      return novastr;
    },
    getCustomerSefaz() {
      if (this.form.cnpj.length == 14) {
        this.requesting = true;
        http
          .get("api/customers/cnpj/" + this.form.cnpj)
          .then((r) => {
            this.requesting = false;
            let res = JSON.parse(r.data);

            if (res.status == "ERROR") {
              this.$toast.error(res.message);
            } else {
              this.form.name = res.nome;
              this.form.phone = res.telefone
                .split("/")[0]
                .replace(/[^A-Z0-9]+/gi, "");
              this.form.address = res.logradouro;
              this.form.postal_code = res.cep.replace(/[^A-Z0-9]+/gi, "");
              this.form.number = res.numero;

              if (this.cities) {
                this.cities.forEach((e) => {
                  if (
                    this.retira_acentos(e.name.toLowerCase()) ==
                    this.retira_acentos(res.municipio.toLowerCase())
                  ) {
                    this.form.city_id = e.id;
                  }
                });
              }
            }
          })
          .catch((error) => {
            this.requesting = false;
            this.$toast.error("Não foi possível buscar dados no SEFAZ!");
          });
      } else if (this.form.cnpj.length < 1) {
        this.$toast.error("Preencha o CNPJ!");
      } else {
        console.log(this.form.cnpj.length);
        this.$toast.error("Só é possível buscar por CNPJ!");
      }
    },
    cleanForm() {
      this.form = {
        name: "",
        cnpj: "",
        phone: "",
        address: "",
        postal_code: "",
        city_id: "",
        number: "",
      };
    },
    getCities() {
      this.loading = true;
      http
        .get("api/cities")
        .then((res) => {
          this.loading = false;
          this.cities = res.data;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast.error("Erro ao carregar as cidades!");
        });
    },
    save() {
      if (this.$refs.form.validate()) {
        this.saving = true;
        http
          .post("api/customers", this.form)
          .then((res) => {
            this.saving = false;
            this.$toast.success("Salvo com sucesso");
            if (this.onCte) {
              this.$store.dispatch("set_current_cnpj", "");
              this.$emit("set_cnpj", res.data.customer);
            }
            this.cleanForm();
            this.$refs.form.reset();
          })
          .catch((error) => {
            let errors = Object.entries(error.response.data.errors);

            if (errors) {
              errors.forEach((e) => {
                this.$toast.error(e[1][0]);
              });
            }

            this.saving = false;
            this.$toast.error("Erro ao salvar");
          });
      }
    },
  },
};
</script>

<style scoped>
.sug-name {
  cursor: pointer;
  background-color: blanchedalmond;
  text-align: center;
}
</style>
