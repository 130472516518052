<template>
  <div>
    <h1>Novo cliente</h1>

    <v-btn color="secondary" @click="$router.push('/clientes')">Lista</v-btn>

    <CustomerForm />
    
  </div>
</template>

<script>
import CustomerForm from '../components/Form'
  export default {
    components: {
      CustomerForm,
    },
    mounted () {
      this.$store.dispatch('set_current_cnpj', '')
    },
  }
</script>

<style scoped>

</style>